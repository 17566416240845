<template>
  <div class="listPage">
    <div class="image">
      <img :src="indexImg" style="width: 100%; height: 100%" />
    </div>
    <div class="layout">
      <div class="contentlistPage">
        <div class="left">
          <div class="listSlid">
            <div class="listSlidHd" @click="menuShow">
              <span>{{ slideHdTitle }}</span>
              <i class="iconfont" v-if="!isMenuShow" style="color: #fff;">&#xe65f; </i>
              <i class="iconfont" v-if="isMenuShow" style="color: #fff;">&#xe65e; </i>
            </div>
            <div class="listSlidList" v-if="isMenuShow">
              <div
                class="listSlidListInner"
                :class="{ on: active == item.entTChannelUuid }"
                v-for="(item, index) in slideList"
                :key="index"
              >
                <a v-if="item.url && item.url !== ''" target="_blank" :href="item.url">
                  <div style="margin-left: 40px;">
                    <span>{{ item.name }}</span>
                    <img src="../assets/image/on2.png" class="listImg" />
                    <img src="../assets/image/on1.png" class="listImg2" />
                  </div>
                </a>
                <a
                  v-if="
                    (!item.url || item.url == '') &&
                      item.entTChannelUuid != '01830005' &&
                      item.entTChannelUuid != '01830001' &&
                      item.entTChannelUuid != '01860001' &&
                      item.entTChannelUuid != '01860004'
                  "
                  :href="
                    item.catalog === 1
                      ? `/listPage/${item.entTChannelUuid}`
                      : item.catalog === 2
                      ? `/list/${item.entTChannelUuid}`
                      : `/listPic/${item.entTChannelUuid}`
                  "
                >
                  <div style="margin-left: 40px;">
                    <span>{{ item.name }}</span>
                    <img src="../assets/image/on2.png" class="listImg" />
                    <img src="../assets/image/on1.png" class="listImg2" />
                  </div>
                </a>
                <a v-if="item.entTChannelUuid == '01830005'" :href="`/listPicTwo/01830005`">
                  <div style="margin-left: 40px;">
                    <span>{{ item.name }}</span>
                    <img src="../assets/image/on2.png" class="listImg" />
                    <img src="../assets/image/on1.png" class="listImg2" />
                  </div>
                </a>
                <a v-if="item.entTChannelUuid == '01830001'" :href="`/listVideo/01830001`">
                  <div style="margin-left: 40px;">
                    <span>{{ item.name }}</span>
                    <img src="../assets/image/on2.png" class="listImg" />
                    <img src="../assets/image/on1.png" class="listImg2" />
                  </div>
                </a>
                <a v-if="item.entTChannelUuid == '01860001'" :href="`/listVideo/01860001`">
                  <div style="margin-left: 40px;">
                    <span>{{ item.name }}</span>
                    <img src="../assets/image/on2.png" class="listImg" />
                    <img src="../assets/image/on1.png" class="listImg2" />
                  </div>
                </a>
                <a v-if="item.entTChannelUuid == '01860004'" :href="`/listPicTwo/01860004`">
                  <div style="margin-left: 40px;">
                    <span>{{ item.name }}</span>
                    <img src="../assets/image/on2.png" class="listImg" />
                    <img src="../assets/image/on1.png" class="listImg2" />
                  </div>
                </a>
                <span></span>
              </div>
            </div>
          </div>
        </div>
        <div class="listMainRight fr">
          <div class="listWrap">
            <div class="listHd clearfloat">
              <div class="fl listHdGrop">
                {{ $t('m.listPageOption') }}：<a href="/" style="font-weight: 700; color: #303133;">{{
                  $t('m.listPageHome')
                }}</a>
                <span v-if="viewSlideHdParent">
                  > <a :href="slideHdParentUrl">{{ slideHdParentName }}</a> ></span
                >
                <span style="color: #0162b3;">&nbsp;{{ slideHdName }}</span>
              </div>
            </div>
            <div class="listContainer">
              <div class="detailCont" v-html="pageCont"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getChildChannel, 
// getChannelInfo, 
getArtInfoByChannel, increaseView , getChannelInfoNew} from '@/api/common'
export default {
  data() {
    return {
      isMenuShow: true,
      mathedTitleUrl: '',
      slideHdName1: '',
      slideHdName2: '',
      slideHdTitle: '',
      viewSlideHdParent: true,
      slideHdParentName: '',
      slideHdParentUrl: '',
      slideHdName: '',
      active: 0,
      slideList: [],
      slideListEn: [],
      pageSize: 10,
      pageNum: 1,
      total: 0,
      pageCont: '',
      pageContEn: '',
      indexImg: '',
      hdTitle: '',
      hdName: '',
      applicationUuid: '',
      lang: 'zh-CN'
    }
  },
  mounted() {
    var _this = this
    _this.active = _this.$route.params.id
    _this.listId = _this.$route.params.id
    // let lang = getCookie('lang')
    let lang = localStorage.getItem('lang')
    if (lang == 'en-US') {
      this.applicationUuid = this.webIdEn
    } else {
      this.applicationUuid = this.webId
    }
    _this.getChannelInfo()
    _this.getArtInfoByChannel()
  },
  methods: {
    menuShow() {
      this.isMenuShow = !this.isMenuShow
    },
    // 查询二级栏目
    getChildChannel(id) {
      var _this = this
      let data = {
        applicationUuid: this.applicationUuid,
        parentUuid: id
      }
      getChildChannel(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.slideList = data
          if (_this.slideList.length) {
            _this.viewSlideHdParent = true
            let url = ''
            if (_this.slideList[0].catalog === 1) {
              url = `/listPage/${_this.slideList[0].entTChannelUuid}`
            } else if (_this.slideList[0].catalog === 2) {
              url = `/list/${_this.slideList[0].entTChannelUuid}`
            } else {
              url = `/list/${_this.slideList[0].entTChannelUuid}`
            }
            _this.slideHdParentUrl = url
          } else {
            _this.viewSlideHdParent = false
          }
        }
      })
    },
    getChannelInfo() {
      var _this = this
      let data = {
        channelUuid: _this.$route.params.id,
        applicationUuid: this.applicationUuid,
      }
      getChannelInfoNew(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.slideHdName = data.name
          if (data.parentUuid == '0') {
            _this.slideHdTitle = data.name
            _this.viewSlideHdParent = false
            if (data.fileName && data.fileName !== '') {
              _this.indexImg = data.fileName
            } else {
              _this.indexImg = require('../assets/image/partyBuilding.jpg')
            }
            return
          }

          _this.getChildChannel(data.parentUuid)

          let params = {
            channelUuid: data.parentUuid,
            applicationUuid: this.applicationUuid,
          }
          getChannelInfoNew(params).then(res => {
            const { code, data } = res
            if (code === 1 && data) {
              _this.slideHdParentName = data.name
              _this.slideHdTitle = data.name
              if (data.fileName && data.fileName !== '') {
                _this.indexImg = data.fileName
              } else {
                _this.indexImg = require('../assets/image/partyBuilding.jpg')
              }
            }
          })
        }
      })
    },

    getArtInfoByChannel() {
      var _this = this

      let data = {
        entTApplicationUuid: this.applicationUuid,
        channelUuid: _this.$route.params.id
      }
      getArtInfoByChannel(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.pageCont = data.content
          this.increaseView(data.entTArticleUuid)
        }
      })
    },
    increaseView(id) {
      var _this = this
      let data = {
        entTArticleUuid: id
      }
      increaseView(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.dataView = data
          console.log(111, _this.dataView)
        }
      })
    }
  }
}
</script>

<style scoped>
/* CDN 服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: 'iconfont'; /* Project id 3165120 */
  src: url('//at.alicdn.com/t/c/font_3165120_j24ufv6zcfe.woff2?t=1661313604254') format('woff2'),
    url('//at.alicdn.com/t/c/font_3165120_j24ufv6zcfe.woff?t=1661313604254') format('woff'),
    url('//at.alicdn.com/t/c/font_3165120_j24ufv6zcfe.ttf?t=1661313604254') format('truetype');
}
.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.1px;
  -moz-osx-font-smoothing: grayscale;
}
.listPage .image {
  width: 100%;
  height: 417px;
  overflow: hidden;
  min-width: 1200px;
  max-height: 330px;
  display: flex;
  margin: 0 auto;
  border: none;
  position: relative;
  z-index: 99;
}
.layout {
  width: 1200px;
  margin: 0 auto;
}
.contentlistPage {
  min-width: 1200px;
  min-height: 300px;
  display: flex;
  margin: 20px 0 30px;
}
.left {
  width: 240px;
}
.listMainRight {
  width: 940px;
  padding: 0 0 20px 20px;
  overflow: hidden;
}
.listHd {
  height: 50px;
  display: flex;
}
.listHdGrop {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333;
  opacity: 0.6;
}
.detailCont {
  font-family: '仿宋' !important;
  font-size: 16pt !important;
  line-height: 40px;
}
.listContainer .detailCont h2 {
  font-family: '微软雅黑' !important;
  font-weight: bold !important;
}
</style>
